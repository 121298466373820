<template>
  <div class="animated fadeIn container">
    <b-row class="justify-content-center" style="max-width:680px;margin:0 auto">
      <b-col>
        <b-card-group>
          <b-card no-body class="p-4 mb-0">
            <b-card-body>
              <b-form>
                <h1>로그인</h1>
                <b-input-group class="mb-3 mt-3">
                  <b-input-group-prepend>
                    <b-input-group-text>@</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="email" class="form-control" placeholder="이메일" autocomplete="username email"
                    v-model="email" />
                </b-input-group>
                <b-input-group class="mb-4">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="password" class="form-control" placeholder="비밀번호" autocomplete="current-password"
                    v-model="password" />
                </b-input-group>
                <b-row>
                  <b-col cols="12">
                    <b-button variant="primary" class="px-4 login-btn" @click.prevent="login">로그인</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card no-body class="text-white bg-primary py-5 signup-card">
            <b-card-body class="text-center">
              <div style="position: absolute;top: 50%;left: 0;width:100%;transform: translateY(-50%);">
                <p>
                  <span style="font-size:28px;line-height:28px">순식간에 가입하는</span>
                  <br />
                  <span style="font-size:38px;font-weight:700;line-height:38px">런던문자</span>
                </p>
                <b-button style="background-color:#0086d8;border:none;color:white;" class="active mt-3"
                  @click.prevent="$router.push('/register')">회원가입</b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  created() {
    if (this.user) {
      this.$router.push('/smsSend')
    }
  },
  mounted() {
    document.body.classList.remove('bg-white')
  },
  data() {
    return {
      email: '',
      password: '',
      error: null,
    }
  },
  methods: {
    async login() {
      try {
        this.showSpinner()
        const response = await AuthService.login({
          email: this.email,
          password: this.password,
        })

        const { token, user } = response.data

        window.dataLayer.push({
          'event': 'login',
        })

        window.dataLayer.push({
          'user_id': user.chatKey,
        });

        this.hideSpinner()
        this.$cookie.set('londonsms_token', token, { expires: '1Y', SameSite: 'Lax' })
        this.$store.dispatch('setToken', token)
        this.$store.dispatch('setUser', user)
        this.$router.push('/smsSend')

      } catch (err) {
        this.hideSpinner()
        this.error = err.response.data.error
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>
